export function debounce(func: WatchCallback, timeout = 300): WatchCallback {
	let timer: ReturnType<typeof setTimeout>

	return function (...args: any[]): void {
		clearTimeout(timer)
		timer = setTimeout(() => func(undefined, args), timeout)
	}
}

export type WatchCallback<T = any> = (newValue: T, oldValue: T) => void
export type WatcherFunction = (element?: HTMLElement, eventName?: string) => void
